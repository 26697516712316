import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { genericError } from './ui/Error'

export default function Error({ children }) {

    let ErrorBoundary = ReactErrorBoundary

    if (import.meta.env.VITE_BUGSNAG_API_KEY) {
        Bugsnag.start({
            apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
            releaseStage: import.meta.env.VITE_APP_ENV,
            plugins: [new BugsnagPluginReact()]
        })

        ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
    }

    return (
        <ErrorBoundary FallbackComponent={() => genericError}>
            {children}
        </ErrorBoundary>
    )
}
