import { Link } from 'react-router-dom'

function Error(code, title, message, cta = null) {
  return (
    <div className="bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-blue-600 sm:text-5xl">
            {code || <img className="h-10 w-auto" src="/img/logo-gray.svg" />}
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{title}</h1>
              <p className="mt-4 text-base text-gray-500">{message}</p>
              {cta &&
                <p className="mt-4 text-base text-gray-500">{cta}</p>
              }
            </div>

            <div className="mt-8 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              {code ?
                <Link
                  to={"/"}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Go back home
                </Link>
                :
                <a href={"/"}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Go back home
                </a>
              }
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export const error401 = Error(401, 'Unauthorized', 'Oops! You are not authorized to access this resource.')
export const error404 = Error(404, 'Page not found', 'Please check the URL in the address bar and try again.')

export const error403 = Error(403, 'Unauthorized', 'Oops! You are not authorized to access this resource.')

export const genericError = (
  <>
    <header className="bg-tukios-navy h-10" />
    {Error(null, 'Oops!', 'An error has occurred. Please refresh the page and try again or contact your administrator.', 'Alternatively, you may return home.')}
  </>
)
