import {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {classNames, filled} from "../Helpers"
import {isString} from "lodash"
import {XCircleIcon, XMarkIcon} from "@heroicons/react/24/solid"

export default function ModalStandalone({open , onCancel = () => {}, onClose = () => {}, title = '', subtitle = '', Text = null, bodyClasses = '', width = 'max-w-3xl', cancelButton = true, buttons = null}) {

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className={classNames("relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6", width)}>
                                <div>
                                    <div>
                                        <button type="button" className="absolute top-1.5 right-1.5 bg-white rounded-full w-6 h-6" onClick={onClose}><XMarkIcon className="h-6 w-6 text-gray-600"  /></button>
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {title}
                                            {filled(subtitle) &&
                                                <span className="text-base text-gray-400 ml-4">{subtitle}</span>
                                            }
                                        </Dialog.Title>
                                        {filled(Text) && (
                                            <div className="mt-2">
                                                {isString(Text) ? (
                                                    <p className={classNames("text-sm bg-black p-6 h-[500px] overflow-y-scroll whitespace-pre-wrap text-[color:#28FE14]", bodyClasses)}>
                                                        {Text}
                                                    </p>
                                                ) : (
                                                    <>
                                                        <Text />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {cancelButton && (
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={onCancel}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
